export const options = [
  {
    id: 0,
    name: "Hambúrguer",
    prices: {
      normal: 11,
    },
  },
  {
    id: 1,
    name: "Carne desfiada",
    prices: {
      normal: 15,
    },
  },
  {
    id: 2,
    name: "Frango desfiado",
    prices: {
      normal: 15,
    },
  },
  {
    id: 3,
    name: "Milho",
    prices: {
      normal: 12,
    },
  },
  {
    id: 4,
    name: "Bacon",
    prices: {
      normal: 15,
    },
  },
  {
    id: 5,
    name: "Calabresa",
    prices: {
      normal: 13,
    },
  },
  {
    id: 6,
    name: "Lombo",
    prices: {
      normal: 16,
    },
  },
  {
    id: 7,
    name: "Picanha",
    prices: {
      normal: 20,
    },
  },
  {
    id: 8,
    name: "Ovo",
    prices: {
      normal: 9,
    },
  },
  {
    id: 9,
    name: "Queijo",
    prices: {
      normal: 10,
    },
  },
  {
    id: 10,
    name: "Cheddar",
    prices: {
      pequeno: 8,
      grande: 10,
    },
  },
  {
    id: 11,
    name: "Catupiry",
    prices: {
      pequeno: 11,
      grande: 13,
    },
  },
  {
    id: 12,
    name: "Batata palha",
    prices: {
      pequeno: 6,
      grande: 8,
    },
  },
];

export const list = [
  {
    id: 1,
    name: "Lanches",
    img: "/images/x-tudo.jpg",
    description: "Lanches grandes, prensados e saborosos",
    products: [
      {
        id: 0,
        name: "Cachorro",
        ingredients: "Salsicha, batata palha, maionese, catchup e vinagrete.",
        prices: {
          normal: 17,
        },
        img: "",
      },
      {
        id: 1,
        name: "Cachorro Duplo",
        ingredients:
          "Duas salsichas, batata palha, maionese, catchup e vinagrete.",
        prices: {
          normal: 19,
        },
      },
      {
        id: 2,
        name: "Hambúrguer",
        ingredients: "Hambúrguer e maionese.",
        prices: {
          pequeno: 21,
          grande: 28,
        },
        img: "",
      },
      {
        id: 3,
        name: "X Burguer",
        ingredients: "Hambúrguer, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 22,
          grande: 30,
        },
        img: "",
      },
      {
        id: 4,
        name: "X Salada",
        ingredients:
          "Hambúrguer, queijo, alface, tomate em rodelas, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 27,
          grande: 32,
        },
        img: "",
      },
      {
        id: 5,
        name: "X Salada Duplo",
        ingredients:
          "Dois hambúrgueres, queijo, alface, tomate em rodelas, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 31,
          grande: 39,
        },
        img: "",
      },
      {
        id: 6,
        name: "X Bacon",
        ingredients:
          "Hambúrguer, queijo, bacon, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 33,
          grande: 45,
        },
        img: "/images/x-bacon.jpeg",
      },
      {
        id: 7,
        name: "X Milho",
        ingredients:
          "Hambúrguer, queijo, milho, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 33,
          grande: 45,
        },
      },
      {
        id: 8,
        name: "X Tudo",
        ingredients:
          "Hambúrguer, queijo, calabresa, ovo, bacon, presunto, frango, carne desfiada, alface, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 47,
          grande: 64,
        },
        img: "/images/x-tudo.jpg",
      },
      {
        id: 9,
        name: "X Egg",
        ingredients: "Hambúrguer, queijo, ovo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 30,
          grande: 42,
        },
      },
      {
        id: 10,
        name: "Prensão",
        ingredients:
          "(Pão bola) Presunto, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 30,
          grande: 43,
        },
      },
      {
        id: 11,
        name: "Calabresão",
        ingredients: "Calabresa, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 33,
          grande: 46,
        },
      },
      {
        id: 12,
        name: "Calaburguer",
        ingredients:
          "Calabresa, hambúrguer, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 35,
          grande: 51,
        },
      },
      {
        id: 13,
        name: "Americano",
        ingredients:
          "Ovo, presunto, queijo, alface, maionese, catchup e vinagrete. Opção: hambúrguer.",
        prices: {
          pequeno: 34,
          grande: 46,
        },
        img: "/images/americano.jpeg",
      },
      {
        id: 14,
        name: "Carnão",
        ingredients:
          "Carne bovina desfiada, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 40,
          grande: 54,
        },
      },
      {
        id: 15,
        name: "Frangão",
        ingredients:
          "Frango desfiado temperado, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 38,
          grande: 52,
        },
      },
      {
        id: 16,
        name: "Churrasco",
        ingredients: "Carne bovina, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 41,
          grande: 58,
        },
      },
      {
        id: 17,
        name: "Filé de Frango",
        ingredients:
          "Filé de frango temperado, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 39,
          grande: 55,
        },
      },
      {
        id: 18,
        name: "Calabacon",
        ingredients: "Calabresa, bacon, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 35,
          grande: 52,
        },
      },
      {
        id: 19,
        name: "Frango c/ Bacon",
        ingredients:
          "Frango desfiado, bacon, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 40,
          grande: 54,
        },
      },
      {
        id: 20,
        name: "Frango c/ Milho",
        ingredients:
          "Frango desfiado, milho, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 40,
          grande: 54,
        },
      },
      {
        id: 21,
        name: "Frango c/ Calabresa",
        ingredients:
          "Frango desfiado, calabresa, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 40,
          grande: 54,
        },
      },
      {
        id: 22,
        name: "Carnão c/ Bacon",
        ingredients:
          "Carne bovina desfiada, bacon, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 43,
          grande: 57,
        },
      },
      {
        id: 23,
        name: "Carnão c/ Calabresa",
        ingredients:
          "Carne bovina desfiada, calabresa, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 43,
          grande: 57,
        },
      },
      {
        id: 24,
        name: "Carnão c/ Milho",
        ingredients:
          "Carne bovina desfiada, milho, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 43,
          grande: 57,
        },
      },
      {
        id: 25,
        name: "Bauru",
        ingredients:
          "Presunto, queijo, tomate em rodelas, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 32,
          grande: 45,
        },
      },
      {
        id: 26,
        name: "Lombo",
        ingredients: "Lombo suíno, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 43,
          grande: 57,
        },
      },
      {
        id: 27,
        name: "Lombo c/ Bacon",
        ingredients:
          "Lombo suíno, bacon, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 45,
          grande: 59,
        },
      },
      {
        id: 28,
        name: "Picanha",
        ingredients: "Picanha, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 45,
          grande: 59,
        },
      },
      {
        id: 29,
        name: "Picanha c/ Bacon",
        ingredients: "Picanha, bacon, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 49,
          grande: 60,
        },
      },
      {
        id: 30,
        name: "X 4 Queijos c/ Hambúrguer",
        ingredients:
          "Hambúrguer, mussarela, provolone, queijo prato e catupiry.",
        prices: {
          pequeno: 43,
          grande: 55,
        },
      },
      {
        id: 31,
        name: "X 4 Queijos",
        ingredients: "Mussarela, provolone, queijo prato e catupiry.",
        prices: {
          pequeno: 40,
          grande: 53,
        },
        tag: {
          name: "Sem carne",
          color: "green",
        },
      },
      {
        id: 32,
        name: "Carnão c/ Frango",
        ingredients:
          "Carne bovina desfiada, frango desfiado, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 43,
          grande: 57,
        },
      },
      {
        id: 33,
        name: "Lombo Calabresa",
        ingredients:
          "Lombo suíno, calabresa, queijo maionese, catchup e vinagrete.",
        prices: {
          pequeno: 45,
          grande: 57,
        },
      },
      {
        id: 34,
        name: "Vegetariano",
        ingredients:
          "Alface, queijo, milho, ovo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 34,
          grande: 45,
        },
        tag: {
          name: "Sem carne",
          color: "green",
        },
      },
      {
        id: 35,
        name: "Palmito c/ Catupiry",
        ingredients:
          "Palmito, catupiry, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 40,
          grande: 57,
        },
        tag: {
          name: "Sem carne",
          color: "green",
        },
      },
      {
        id: 36,
        name: "Palmito c/ Milho",
        ingredients: "Palmito, milho, queijo, maionese, catchup e vinagrete.",
        prices: {
          pequeno: 36,
          grande: 51,
        },
        tag: {
          name: "Sem carne",
          color: "green",
        },
      },
    ],
    options,
  },
  {
    id: 2,
    name: "Especiais",
    img: "/images/mini-salada.jpeg",
    description: "Mini burguers, lanches na baguete e mais",
    products: [
      {
        id: 0,
        name: "Linguiça na Baguete",
        ingredients: "Linguiça, queijo, maionese, catchup e vinagrete.",
        prices: {
          baguete: 36,
        },
      },
      {
        id: 8,
        name: "Omelete",
        ingredients:
          "Três opções de recheio, alface, tomate em rodelas e queijo.",
        prices: {
          normal: 42,
        },
        obs: "Opções de Recheio: Presunto/ Queijo Provolone/ Milho / Bacon / Calabresa/ Palmito / Frango Desfiado / Carne desfiada / Cheddar / Catupiry",
      },
      {
        id: 1,
        name: "Combo Mini Especial",
        ingredients: "Dois Mini Burguers a escolha",
        prices: {
          combo: 39,
        },
        obs: "Acompanha: fritas e refrigerante 200ml",
      },
      {
        id: 2,
        name: "Mini X-Burguer",
        ingredients:
          "Pão com gergelim (sem prensar), hambúrguer, queijo, maionese e catchup",
        prices: {
          mini: 27,
        },
        obs: "Acompanha: fritas e refrigerante 200ml",
      },
      {
        id: 3,
        name: "Mini Salada",
        ingredients:
          "Pão com gergelim (sem prensar), hambúrguer, queijo, alface, tomate, maionese e catchup",
        prices: {
          mini: 28,
        },
        obs: "Acompanha: fritas e refrigerante 200ml",
        img: "/images/mini-salada.jpeg",
      },
      {
        id: 4,
        name: "Mini Burguer Duplo",
        ingredients:
          "Pão com gergelim (sem prensar), hambúrguer, queijo, maionese e catchup",
        prices: {
          mini: 29,
        },
        obs: "Acompanha: fritas e refrigerante 200ml",
      },
      {
        id: 5,
        name: "Mini Bacon",
        ingredients:
          "Pão com gergelim (sem prensar), hambúrguer, bacon, queijo, maionese e catchup",
        prices: {
          mini: 29,
        },
        obs: "Acompanha: fritas e refrigerante 200ml",
        img: "/images/mini-bacon.jpeg",
      },
      {
        id: 6,
        name: "Mini Egg",
        ingredients:
          "Pão com gergelim (sem prensar), hambúrguer, ovo, queijo, maionese e catchup",
        prices: {
          mini: 28,
        },
        obs: "Acompanha: fritas e refrigerante 200ml",
      },
      {
        id: 7,
        name: "Mini Milho",
        ingredients:
          "Pão com gergelim (sem prensar), hambúrguer, milho, queijo, maionese e catchup",
        prices: {
          mini: 29,
        },
        obs: "Acompanha: fritas e refrigerante 200ml",
      },
    ],
    options,
  },
  {
    id: 3,
    name: "Porções",
    img: "/images/fries.jpg",
    description: "Porções de carne e fritas",
    products: [
      {
        id: 0,
        name: "Porção de Batata Frita",
        prices: {
          pequena: 16,
          média: 23,
          grande: 27,
        },
      },
      {
        id: 1,
        name: "Filé de Frango",
        prices: {
          normal: 48,
        },
      },
      {
        id: 2,
        name: "Lombo de Porco",
        prices: {
          normal: 48,
        },
      },
      {
        id: 3,
        name: "Carne Acebolada",
        prices: {
          normal: 48,
        },
      },
      {
        id: 4,
        name: "Calabresa Acebolada",
        prices: {
          normal: 48,
        },
      },
    ],
  },
  {
    id: 4,
    name: "Bebidas",
    img: "/images/orange.jpg",
    description: "Sucos naturais ou de polpa",
    products: [
      {
        id: 0,
        name: "Laranja natural",
        prices: {
          "300ml": 8,
          "500ml": 11,
        },
        ingredients: "Suco de laranja natural espremido na máquina.",
      },
      {
        id: 1,
        name: "Maracujá Polpa",
        prices: {
          Água: 10,
          Leite: 14,
        },
        ingredients: "500ml de suco de polpa.",
      },
      {
        id: 2,
        name: "Morango Polpa",
        prices: {
          Água: 10,
          Leite: 14,
        },
        ingredients: "500ml de suco de polpa.",
      },
      {
        id: 3,
        name: "Acerola Polpa",
        prices: {
          Água: 10,
          Leite: 14,
        },
        ingredients: "500ml de suco de polpa.",
      },
      {
        id: 4,
        name: "Caju Polpa",
        prices: {
          Água: 10,
          Leite: 14,
        },
        ingredients: "500ml de suco de polpa.",
      },
      {
        id: 5,
        name: "Abacaxi Polpa",
        prices: {
          Água: 10,
          Leite: 14,
        },
        ingredients: "500ml de suco de polpa.",
      },
    ],
  },
];
