import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faWhatsappSquare,
} from "@fortawesome/free-brands-svg-icons";

import Phone from "../phone/Phone";

export default function Localization(props) {
  const phoneElements = props.phones.map((phone) => (
    <Phone key={phone} phone={phone} />
  ));

  return (
    <section className="localization">
      <div className="info">
        <p className="disk-tag">Disk Lanches</p>
        {phoneElements}
        <div className="info-block">
          <FontAwesomeIcon icon={faClock} />
          <div className="info-block-text">
            <p>Aberto de segunda a sábado</p>
            <p>Das 18h às 00h</p>
          </div>
        </div>
        <div className="info-block">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <div className="info-block-text">
            <p>Rua Angelo Gatti, n° 251</p>
            <p>Vila Gatti</p>
            <p>Itu/SP</p>
            <p>13300-313</p>
          </div>
        </div>
        <div className="social">
          <a
            className="social-media"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/betos.lanches.itu"
            title="Facebook"
          >
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
          <a
            className="social-media"
            target="_blank"
            rel="noopener noreferrer"
            href="https://api.whatsapp.com/send?phone=5511996424271&amp;text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20pedido"
            title="WhatsApp"
          >
            <FontAwesomeIcon icon={faWhatsappSquare} />
          </a>
        </div>
      </div>
      <div className="map">
        <iframe
          title="Como nos encontrar"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14660.798104022046!2d-47.3017323!3d-23.2721987!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x10adeddec872c67f!2sBeto's+Lanches!5e0!3m2!1spt-BR!2sbr!4v1480741514481"
        ></iframe>
      </div>
    </section>
  );
}
